.progress-loader .modal-backdrop {
  position: fixed;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: .5;
}

.progress-loader .MuiLinearProgress-barColorSecondary {
  background-color: var(--loader-color-1);
}

.progress-loader .MuiLinearProgress-colorSecondary {
  background-color: var(--loader-color-2);
}

.progress-loader .MuiLinearProgress-root {
  position: fixed;
  z-index: 10;
  width: 100%;
}