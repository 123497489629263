.notifier-container {
  position: relative;
  margin: 10px 20px 0 20px;
}

.notifier-container, .notifier-container > div {
  display: inline-flex;
}

.notifier-container .pi-bell {
  font-size: 19px;
  cursor: pointer;
}

.notifier-footer .p-button {
  width: 100%;
  font-weight: normal;
}

.notifier-overlay {
  width: 320px;
}

.notifier-overlay-content {
  height: 250px;
  overflow: auto;
  flex-direction: row;
  margin-bottom: 10px;
}

.notifier-overlay-content::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.notifier-overlay-content::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.notifier-overlay-content::-webkit-scrollbar-thumb
{
	background-color: #495057;
}

.notifier-overlay .name {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
}

.notifier-overlay .description {
  font-size: 13px;
  line-height: 20px;
}

.notifier-overlay .circle {
  width: 48px;
  height: 48px;
  padding: 12px;
  background-color: #26b494;
  border-radius: 24px;
}

.notifier-container .p-badge {
  position: absolute;
  top: -5px;
  left: 13px;
  height: 1.3em;
  min-width: 1.3em;
  line-height: 1.3em;
  font-size: 0.7em;
}