.notifier-profiler {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
}

.profiler-overlay .primary-button {
  background: var(--button-hover-bg);
  color: var(--button-bg);
}

.profiler-overlay .primary-button:hover {
  background: var(--button-bg);
  color: var(--button-color);
}

.profiler-overlay .description {
  font-weight: bold;
}

.profiler-overlay {
  --overlayArrowLeft: 120px !important;
}

.profiler-overlay .horizontal-line {
  height: 1px;
  background: #e8eafd;
  margin: 10px -8px;
}

.profiler-overlay a {
  cursor: pointer;
  text-decoration: underline;
}

.p-overlaypanel-content {
  font-size: 13px;
}

.p-overlaypanel-content a {
  margin-top: 10px;
  display: block;
}