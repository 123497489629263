.nav-panel {
  color: var(--input-color);
  padding: 0px 10px;
  background-color: var(--main-bg);
  width: 100%;
  height: var(--nav-panel-height);
  line-height: var(--nav-panel-height);
  font-weight: bold;
  box-shadow: 0px 5px 20px #F2F7FA;
  position: absolute;
  z-index: 1;
}

.nav-panel a,
.nav-panel a:hover  {
  color: var(--input-color);
  text-decoration: none;
  font-size: 1.75rem;
}

.nav-bar-right-panel {
  display: flex;
  align-items: center;
}

.nav-bar-right-panel svg {
  margin-right: 16px;
  cursor: pointer;
}

.pi.pi-user-profile {
  width: 22px;
  height: 22px;
  background: var(--input-color);
  border-radius: 20px;
  color: #FFF;
  cursor: pointer;
  margin-right: 5px;
}

.pi.pi-user-profile span {
  line-height: 22px;
  font-size: 15px;
  display: table;
  margin: auto;
}