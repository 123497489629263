.tab-menu {
  display: flex;
  align-items: center;
}

.tab-menu div {
  margin-right: 20px;
  font-weight: normal;
  cursor: pointer;
  border-top: 10px solid transparent;
}

.tab-menu div.selected {
  font-weight: bold;
  border-color: #B50000;
}