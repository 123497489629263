:root {
  --grid-expand-collapse-color: #FFF;
  --grid-expand-collapse-hover-color: #000;
  --expanded-row-bg: #FFF;
  --expanded-row-color: #000;
}
.pi-info-name {
  margin-right: 15px;
  float: left;
}

.pi-heart {
  cursor: pointer;
  color: var(--fv-gb);
}

.pi-heart.pi-heart-selected {
  color: var(--selected-fv-gb);
}

.main-link {
  border: 0;
  background: transparent;
  text-decoration: underline;
  color: var(--input-color);
  cursor: pointer;
  font-size: 15px;
}

.error-logs-link {
  border: 0;
  background: transparent;
  text-decoration: underline;
  color: var(--expanded-row-color);
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  padding-left: 0;
}

.grid-panel {
  padding: 12px 0px 12px var(--page-padding-left);
  background: var(--main-bg);
}

.grid-panel-right {
  margin-right: var(--page-padding-left);
}

.grid-panel-right svg {
  cursor: pointer;
}

.search-container {
  background: var(--main-bg);
  padding-left: var(--page-padding-left);
  margin-left: 0;
  margin-right: 0;
}
.search-container .p-input-icon-left .pi-search {
  color: var(--input-color);
}
.search-container .p-inputtext {
  width: 100%;
  color: var(--input-color);
  height: 44px;
}

.search-container .p-inputtext::placeholder {
  color: var(--input-color);
  opacity: 1;
}

.search-container .p-inputtext:-ms-input-placeholder {
  color: var(--input-color);
}

.search-container .p-inputtext::-ms-input-placeholder {
  color: var(--input-color);
}

.info-expander .pi-chevron-right:before {
  content: "\e924";
  color: var(--grid-expand-collapse-color);
}

.info-expander .pi-chevron-down:before {
  content: "\e90b";
  color: var(--grid-expand-collapse-color);
}

.info-expander.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover .pi-chevron-right:before,
.info-expander.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover .pi-chevron-down:before {
  color: var(--grid-expand-collapse-hover-color);
}

.info-expander-expanded-row {
  background: var(--expanded-row-bg);
  color: var(--expanded-row-color);
  font-size: 17px;
  line-height: 20px;
  margin-top: 0;
}

.info-expander-expanded-row > div.p-col-4 {
  border: 1px solid #CCC;
  border-top: 0;
  border-left: 0;
}

.info-expander-expanded-row > div.p-col-8 {
  border: 1px solid #CCC;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-weight: bold;
}

.info-expander tr[id^=content_] td {
  padding: 0 10px;
}

.info-expander-expanded-row-arrow-up {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #FFF;
  margin-left: 15px;
}

.favorite-page-header {
  color: var(--input-color);
  font-size:30px;
  height: 60px;
  display: flex;
  align-items: center;
}

.patient-details .patient-header svg,
.device-details .device-header svg {
  margin: 0 20px;
  width: 15px;
  height: 18px;
}

.patient-details .patient-header, 
.device-details .device-header {
  display: flex;
  flex-direction: row;
  color: #FFF;
  font-size: 30px;
  align-items: baseline;
  padding-top: 10px;
}

.patient-details .patient-mrn,
.device-details .device-model {
  font-size: 15px;
  color: #B3BABE;
  margin-left: 55px;
}

.patient-details .patient-metadata,
.device-details .device-metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 15px;
  padding: 0 55px;
}

.patient-details .patient-metadata .patient-metadata-column,
.device-details .device-metadata .device-metadata-column {
  display: flex;
  flex-direction: column;
}

.patient-details .patient-metadata .patient-metadata-column > span:nth-child(1),
.device-details .device-metadata .device-metadata-column > span:nth-child(1) {
  color: #FFF;
  font-size: 20px;
}

.patient-details .patient-metadata .patient-metadata-column > span:nth-child(2),
.device-details .device-metadata .device-metadata-column > span:nth-child(2) {
  color: #B3BABE;
  font-size: 12px;
}

.report-holder-gap {
  background: #F8FCFE;
  height: 20px;
}

.report-holder {
  background: #F8FCFE;
  height: calc(100vh - 228px);
  overflow-y: auto;
  text-align: center;
}

.report-holder::-webkit-scrollbar,
.items-holder::-webkit-scrollbar {
  width: 10px;
}

.report-holder::-webkit-scrollbar-track,
.items-holder::-webkit-scrollbar-track {
  background: #BFC5C7; 
}
 
.report-holder::-webkit-scrollbar-thumb,
.items-holder::-webkit-scrollbar-thumb {
  background: #555; 
}

.report-holder::-webkit-scrollbar-thumb:hover,
.items-holder::-webkit-scrollbar-thumb:hover {
  background: var(--filter-bg); 
}

.details-container {
  width: 90%;
  text-align: center;
  background: #FFF;
  box-shadow: 0px 0px 20px #D8DCDE;
  margin: 0 20px 20px 20px; 
  border-radius: 10px;
  padding-bottom: 5px;
}

.p-datatable-tbody td {
  vertical-align: top;
}

.see-all-items {
  height: 46px;
  line-height: 46px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  background: #D9F0F8;
  font-size: 18px;
  padding: 0 11px;
  align-items: center;
  color: #495057;
  border-radius: 10px;
  margin: 10px auto 20px auto;
  cursor: pointer;
}

.see-all-items:hover {
  background-color: #009DCC;
  color: #FFF;
}

.items-holder {
  background: #F8FCFE;
  overflow-y: auto;
  text-align: center;
  height: calc(100vh - 339px);
}

.tabs .tab {
  display: flex;
  align-items: center;
}

.tabs .tab svg {
  margin-right: 10px;
}

.device-errors {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.device-errors span:nth-child(1) {
  font-weight: bold;
  color: var(--input-color);
}

.device-header button {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 5px;
}

:root {
  --grid-expand-collapse-color: #FFF;
  --grid-expand-collapse-hover-color: #000;
  --expanded-row-bg: #FFF;
  --expanded-row-color: #000;
}
.pi-info-name {
  margin-right: 15px;
  float: left;
}

.pi-heart {
  cursor: pointer;
  color: var(--fv-gb);
}

.pi-heart.pi-heart-selected {
  color: var(--selected-fv-gb);
}

.main-link {
  border: 0;
  background: transparent;
  text-decoration: underline;
  color: var(--input-color);
  cursor: pointer;
  font-size: 15px;
}

.error-logs-link {
  border: 0;
  background: transparent;
  text-decoration: underline;
  color: var(--expanded-row-color);
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  padding-left: 0;
}

.grid-panel {
  padding: 12px 0px 12px var(--page-padding-left);
  background: var(--main-bg);
}

.grid-panel-right {
  margin-right: var(--page-padding-left);
}

.grid-panel-right svg {
  cursor: pointer;
}

.search-container {
  background: var(--main-bg);
  padding-left: var(--page-padding-left);
  margin-left: 0;
  margin-right: 0;
}
.search-container .p-input-icon-left .pi-search {
  color: var(--input-color);
}
.search-container .p-inputtext {
  width: 100%;
  color: var(--input-color);
  height: 44px;
}

.search-container .p-inputtext::placeholder {
  color: var(--input-color);
  opacity: 1;
}

.search-container .p-inputtext:-ms-input-placeholder {
  color: var(--input-color);
}

.search-container .p-inputtext::-ms-input-placeholder {
  color: var(--input-color);
}

.info-expander .pi-chevron-right:before {
  content: "\e924";
  color: var(--grid-expand-collapse-color);
}

.info-expander .pi-chevron-down:before {
  content: "\e90b";
  color: var(--grid-expand-collapse-color);
}

.info-expander.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover .pi-chevron-right:before,
.info-expander.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover .pi-chevron-down:before {
  color: var(--grid-expand-collapse-hover-color);
}

.info-expander-expanded-row {
  background: var(--expanded-row-bg);
  color: var(--expanded-row-color);
  font-size: 17px;
  line-height: 20px;
  margin-top: 0;
}

.info-expander-expanded-row > div.p-col-4 {
  border: 1px solid #CCC;
  border-top: 0;
  border-left: 0;
}

.info-expander-expanded-row > div.p-col-8 {
  border: 1px solid #CCC;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-weight: bold;
}

.info-expander tr[id^=content_] td {
  padding: 0 10px;
}

.info-expander-expanded-row-arrow-up {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #FFF;
  margin-left: 15px;
}

.favorite-page-header {
  color: var(--input-color);
  font-size:30px;
  height: 60px;
  display: flex;
  align-items: center;
}

.patient-details .patient-header svg,
.device-details .device-header svg {
  margin: 0 20px;
  width: 15px;
  height: 18px;
}

.patient-details .patient-header, 
.device-details .device-header {
  display: flex;
  flex-direction: row;
  color: #FFF;
  font-size: 30px;
  align-items: baseline;
  padding-top: 10px;
}

.patient-details .patient-mrn,
.device-details .device-model {
  font-size: 15px;
  color: #B3BABE;
  margin-left: 55px;
}

.patient-details .patient-metadata,
.device-details .device-metadata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 15px;
  padding: 0 55px;
}

.patient-details .patient-metadata .patient-metadata-column,
.device-details .device-metadata .device-metadata-column {
  display: flex;
  flex-direction: column;
}

.patient-details .patient-metadata .patient-metadata-column > span:nth-child(1),
.device-details .device-metadata .device-metadata-column > span:nth-child(1) {
  color: #FFF;
  font-size: 20px;
}

.patient-details .patient-metadata .patient-metadata-column > span:nth-child(2),
.device-details .device-metadata .device-metadata-column > span:nth-child(2) {
  color: #B3BABE;
  font-size: 12px;
}

.report-holder-gap {
  background: #F8FCFE;
  height: 20px;
}

.report-holder {
  background: #F8FCFE;
  height: calc(100vh - 228px);
  overflow-y: auto;
  text-align: center;
}

.report-holder::-webkit-scrollbar,
.items-holder::-webkit-scrollbar {
  width: 10px;
}

.report-holder::-webkit-scrollbar-track,
.items-holder::-webkit-scrollbar-track {
  background: #BFC5C7; 
}
 
.report-holder::-webkit-scrollbar-thumb,
.items-holder::-webkit-scrollbar-thumb {
  background: #555; 
}

.report-holder::-webkit-scrollbar-thumb:hover,
.items-holder::-webkit-scrollbar-thumb:hover {
  background: var(--filter-bg); 
}

.details-container {
  width: 90%;
  text-align: center;
  background: #FFF;
  box-shadow: 0px 0px 20px #D8DCDE;
  margin: 0 20px 20px 20px; 
  border-radius: 10px;
  padding-bottom: 5px;
}

.p-datatable-tbody td {
  vertical-align: top;
}

.see-all-items {
  height: 46px;
  line-height: 46px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  background: #D9F0F8;
  font-size: 18px;
  padding: 0 11px;
  align-items: center;
  color: #495057;
  border-radius: 10px;
  margin: 10px auto 20px auto;
  cursor: pointer;
}

.see-all-items:hover {
  background-color: #009DCC;
  color: #FFF;
}

.items-holder {
  background: #F8FCFE;
  overflow-y: auto;
  text-align: center;
  height: calc(100vh - 339px);
}

.tabs .tab {
  display: flex;
  align-items: center;
}

.tabs .tab svg {
  margin-right: 10px;
}

.device-errors {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.device-errors span:nth-child(1) {
  font-weight: bold;
  color: var(--input-color);
}

.device-header button {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 5px;
}