:root {
  --main-bg: #FFF;
  --input-bg: #F3FAFD;
  --input-border-radius: 6px;
  --input-color: #001624;
  --button-color-disabled: #FFFFFF;
  --button-bg-disabled: #262834;
  --button-color:#FFFFFF;
  --button-bg: #3A4246;
  --button-hover-bg: #1F9ECA;
  --link-color:#001624;
  --link-color-hover:#001624;
  --input-invalid-color: #e0383e;
  --login-padding: 32px;
  --validation-summary-error-bg: #e0383e;
  --validation-summary-success-bg: #26b494;
  --validation-summary-color: #FFFFFF;
  --selected-tab-bg: #FFFFFF;
  --selected-tab-color: #000000;
  --tab-bg: rgba(0, 0, 0, 0.33);
  --tab-color: #FFFFFF;
  --tab-hover-bg:#2077ff;
  --tab-hover-color:#FFFFFF;
  --fv-gb: #6c757d;
  --selected-fv-gb: #FFFFFF;
  --tab-height: 32px;
  --loader-color-1:#e0383e;
  --loader-color-2:#FFFFFF;
  --page-padding-left: 12px;
  --filter-bg: #001624;
  --alt-row-color: #F7FCFE;
  --nav-panel-height: 56px;
  --highlighted-row: #C6E8F4;
}

html, body, #root, .container-nicas, .favorite-page {
  margin: 0;
  height: 100%;
}

.favorite-page {
  display: flex;
}

body {
  font-family: SFProText, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6c757d;
  background-color: var(--main-bg);
}

.p-inputtext {
  background: var(--input-bg);
  border: 1px solid var(--input-bg);
  border-radius: var(--input-border-radius);
  color: var(--input-color);
  padding-left: 16px;
}

.p-button, .p-togglebutton.p-button {
  background: var(--button-bg);
  color: var(--button-color);
  border-radius: var(--input-border-radius);
  border: 1px solid var(--button-bg);
}

.p-button:enabled:hover, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--button-hover-bg);
}

.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, 
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  color: var(--button-color);
}

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover {
  background: var(--button-hover-bg);
  border-color: var(--button-hover-bg);
}

.p-button:disabled {
  background: var(--button-bg-disabled);
  border-color: var(--button-bg-disabled);
  color: var(--button-color-disabled);
}

.p-invalid {
  border-color: var(--input-invalid-color);
}

.tooltip-nicas .p-tooltip-arrow {
  border-bottom-color: #FFFFFF !important;
}

.tooltip-nicas .p-tooltip-text {
  background-color: #FFFFFF;
  letter-spacing: 0.23px;
  color: #000000;
}

a {
  color: var(--link-color);
}

a:hover {
  color: var(--link-color-hover);
}

.login-logo {
  margin: 5% 0 0 5%;
}

.dashboard-logo-container, .dashboard-logo-container a {
  display: flex;
}

.dashboard-logo {
  width: 190px;
  align-self: center;
  margin-left: 10px;
}

.anonymus-container {
  height: 80vh;
  width: 100%;
  padding-left: 5%;
}

.anonymus-container .p-card {
  background: var(--main-bg);
  border: 1px solid var(--main-bg);
  border-radius: 12px;
}

.anonymus-container .p-card-title {
  color: var( --input-color);
}

.anonymus-container .p-card-subtitle {
  color: var( --input-color);
}

.anonymus-container .p-card-body {
  padding: var(--login-padding);
  color: var( --input-color);
}

.horizontal-line {
  height: 1px;
  margin: var(--login-padding) calc(-1 * var(--login-padding));
  background-color: var(--main-bg);
}

.anonymus-container a {
  margin-top: 24px;
  display: inline-block;
  font-weight: bold;
}

.tabs .p-button {
  height: var(--tab-height);
  font-weight: normal;
}

.tabs.p-selectbutton .p-button {
  background: var(--tab-bg);
  border-color: var(--tab-bg);
  color: var(--tab-color);
  font-size: 20px;
  line-height: var(--tab-height);
}

.tabs.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--tab-hover-bg);
  border-color: var(--tab-hover-bg);
  color: var(--tab-hover-color);
}

.tabs.p-selectbutton .p-button.p-highlight {
  background: var(--selected-tab-bg);
  border-color: var(--selected-tab-bg);
  color: var(--selected-tab-color);
}

.tabs.p-selectbutton .p-button.p-highlight:hover {
  background: var(--tab-hover-bg);
  border-color: var(--tab-hover-bg);
  color: var(--tab-hover-color);
}

.p-datatable .p-datatable-thead > tr > th {
  color: var(--input-color);
  background: var(--main-bg);
  border: 0px;
  font-size: 15px;
}

.p-datatable .p-datatable-tbody > tr {
  color: var(--input-color);
  background: transparent;
  font-size: 17px;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-color: var(--main-bg);
}

.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight:hover, .p-datatable .p-sortable-column:not(.p-highlight):hover {
  color: var(--input-color);
  background: var(--main-bg);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon, 
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--input-color);
}

.info-expander-expanded-row .p-datatable td {
  height: 30px;
}

.info-expander-expanded-row .p-datatable .p-datatable-thead > tr > th {
  color: var(--main-bg);
  background: var(--input-color);
}

.info-expander-expanded-row .p-datatable .p-datatable-tbody > tr {
  color: var(--main-bg);
}

.info-expander-expanded-row .p-datatable .p-sortable-column.p-highlight, .info-expander-expanded-row .p-datatable .p-sortable-column.p-highlight:hover, .info-expander-expanded-row .p-datatable .p-sortable-column:not(.p-highlight):hover {
  color: var(--main-bg);
  background: var(--input-color);
}

.p-dialog .p-dialog-header, .p-dialog .p-dialog-content, .p-dialog .p-dialog-footer {
  background: var(--main-bg);
  color: #FFF;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(255, 255, 255, 0.4);
}

.p-button.p-button-text {
  color: var(--input-color);
}

.p-dialog-footer .p-button.p-button-text {
  color: var(--fv-gb);
}

.p-dialog-footer .p-button.p-button-text:not(a):not(.p-disabled):hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-button.p-button-text:enabled:hover {
  color: var(--link-color-hover);
}
.page-caption {
  font-size: 19px;
  line-height: 23px;
  color: var(--input-color);
  font-weight: bold;
}

.p-component {
  font-family: Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: var(--alt-row-color);
}

.p-datatable-thead tr {
  box-shadow: 0px 5px 8px #f2f7fa
}

.text-overflowed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: var(--highlighted-row);
}

.p-dialog .p-dialog-content {
  padding-top: 5px;
}

.invalid-route {
  padding-top: 100px;
}

.invalid-route div.p-col-6 div {
  width: 100%;
}

.two-column-content {
  display: flex;
  flex-direction: column;
}

.two-column-content > span:nth-child(2) {
  font-size: 12px;
  color: #B3BABE;
}

.favorite-page-layout {
  margin: 4px 0 0 0;
  padding-top: var(--nav-panel-height);
}

.data-view-page-header {
  font-size: 30px;
  color: #263945;
  padding-left: 60px;
}